body {
  color: var(--color-text);
  background-color: var(--color-bg);
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

textarea,
input,
select {
  font-family: "Montserrat", sans-serif;
  outline: none;
}

aside {
  box-shadow: 6px 0 12px 0 rgba(0, 0, 0, 0.04);
}

.page {
  padding: 24px;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 24px;
}

a {
  color: var(--color-blue-light);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: var(--color-blue);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-size: 28px;
  color: var(--color-blue-dark);
  font-weight: 500;
}

/*
hr {
  width: 100%;
}
*/
.error {
  color: var(--color-danger);
}

/*
  BUTTONS
*/

button,
a.button {
  &:not(.NgxEditor__Color) {
    border: none;
    background: linear-gradient(0deg, var(--color-blue-button1), var(--color-blue-button2));
    color: white;
    padding: 16px 24px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    box-sizing: border-box;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.02);
    }

    &:disabled {
      background: var(--color-grey-light) !important;
      color: var(--color-grey);
      cursor: default;
    }

    &.success {
      color: white;
      background-color: var(--color-success);
    }

    &.danger {
      color: white;
      background-color: var(--color-danger);
    }

    &.icon {
      padding: 4px;
      border-radius: 6px;

      min-width: unset;
    }

    img {
      height: 14px;
    }
  }
}

/*
  INPUTS
*/
/*
  input.ng-valid {}
  input.ng-invalid {}
  input.ng-pending {}
  input.ng-pristine {}
  input.ng-dirty {}
  input.ng-untouched {}
  input.ng-touched {}
*/

input,
textarea {
  padding: 10px 20px;
  border: solid 1px var(--color-grey-light);
  font-size: 14px;
  /* outline: solid 1px rgba(0, 0, 0, 0.02);*/
  outline: none;
  background-color: white;

  &.ng-invalid.ng-touched {
    border-color: var(--color-danger);
  }
}

input[type="datetime-local"],
input[type="date"] {
  padding: 9px 20px;
}

input:focus,
textarea:focus {
  border-color: var(--color-light);
}

textarea {
  resize: vertical;
}

input.search {
  padding-right: 36px;
  background-image: url("/assets/icons/search.svg");
  background-size: 24px 24px;
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
  min-width: 100px;
  width: 100%;
}

input.search:focus {
  border-color: var(--color-blue-light);
}

input[readonly],
textarea[readonly],
input[disabled],
textarea[disabled] {
  border-color: var(--color-secondary);
  color: var(--color-text-cr);
}

input[type=checkbox] {
  zoom: 1.5;
  margin: 0;
}

select {
  padding: 10px 50px 10px 20px;
  border: solid 1px var(--color-grey-light);
  font-size: 14px;
  outline: solid 1px rgba(0, 0, 0, 0.02);
  color: var(--color-text-cr);
  background-color: white;
  background-image: url("/assets/icons/chevron-down.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.ng-invalid.ng-touched {
    border-color: var(--color-danger);
  }
}

option {
  border: none;
}

/*
  IMAGE INPUT
*/
.thumbs {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.thumbs-item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-clip: content-box;
  border-radius: 8px;
  border: solid 2px transparent;
  padding: 0px;
  width: 60px;
  height: 60px;
  transition: 0.1s;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.02);
}

.thumbs-item.selected {
  border-color: var(--color-success);
  padding: 2px;
}

/*
  TABLE
*/

.table {
  display: flex;
  flex-direction: column;
}

.table .cell {
  flex: 0 1 18%;
  padding: 12px 10px 12px 30px;
  font-weight: 600;
}

.table-head {
  display: flex;
}

.table-head .cell {
  color: var(--color-grey);
}

.table-head .cell:last-of-type {
  flex: 1 1 0;
}

.table-body {
  border: solid 1px var(--color-grey);
}

.table-body-row {
  display: flex;
}

.table-body-row .cell {
  color: var(--color-blue-dark);
}

.table-body-row .cell:last-of-type {
  flex: 1 1 0;
}

/*
    MODAL
*/

.modal-body {
  border-radius: 16px;
  padding: 24px;
  background-color: white;
  width: 100%;
  max-width: 500px;
  min-height: 200px;
  text-align: center;
}

/*
    BREADCRUMBS
*/

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 600;
  padding: 30px 0;
}

.breadcrumbs img {
  width: 24px;
}

.breadcrumbs .slash {
  color: var(--color-grey);
}

.breadcrumbs a {
  color: var(--color-blue);
}

.breadcrumbs span {
  color: var(--color-grey);
}

/*
* TABS
*/

.tabs-head {
  display: flex;
}

.tabs-head a {
  font-weight: 600;
  padding: 10px 16px;
  border: solid 1px var(--color-grey-light);
}

.tabs-head a.active {
  color: var(--color-blue-dark);
  background-color: var(--color-grey-light);
}

.tabs-head button {
  font-weight: 600;
  padding: 10px 16px;
  border: solid 1px var(--color-grey-light);
  background: none;
  color: var(--color-blue-light);
}

.tabs-head button.active {
  color: var(--color-blue-dark);
  background-color: var(--color-grey-light);
  color: var(--color-blue-dark);
  cursor: context-menu;
}

/* 
* LINDE
*/
/* TODO: REMOVE other items '.star' */
img.star {
  width: 16px;
  opacity: 0.4;
  filter: grayscale(1);
  cursor: pointer;
}

img.star.active {
  opacity: 1;
  filter: none;
}

.msg {
  font-size: 18px;
  font-weight: 500;
}

.success {
  color: var(--color-success);
}

.warning {
  color: var(--color-warning);
}

.danger {
  color: var(--color-danger);
}