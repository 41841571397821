ngx-editor .NgxEditor {
    border-radius: 0;
    border: none;
    border-top: solid 1px var(--color-grey-light);

    &__Content {
        padding: 10px 20px;
        
    }
}

ngx-editor-menu .NgxEditor {

    &__MenuBar {
        padding: 10px 20px;
    }

    &__MenuItem--IconContainer {
        border-radius: 0;
        border: solid 1px var(--color-grey-light);
    }
}