@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.none {
  display: none;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  gap: 12px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

body {
  color: var(--color-text);
  background-color: var(--color-bg);
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

textarea,
input,
select {
  font-family: "Montserrat", sans-serif;
  outline: none;
}

aside {
  box-shadow: 6px 0 12px 0 rgba(0, 0, 0, 0.04);
}

.page {
  padding: 24px;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 24px;
}

a {
  color: var(--color-blue-light);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: var(--color-blue);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-size: 28px;
  color: var(--color-blue-dark);
  font-weight: 500;
}

/*
hr {
  width: 100%;
}
*/
.error {
  color: var(--color-danger);
}

/*
  BUTTONS
*/
button:not(.NgxEditor__Color),
a.button:not(.NgxEditor__Color) {
  border: none;
  background: linear-gradient(0deg, var(--color-blue-button1), var(--color-blue-button2));
  color: white;
  padding: 16px 24px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  box-sizing: border-box;
  transition: 0.3s;
  cursor: pointer;
}
button:not(.NgxEditor__Color):hover,
a.button:not(.NgxEditor__Color):hover {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.02);
}
button:not(.NgxEditor__Color):disabled,
a.button:not(.NgxEditor__Color):disabled {
  background: var(--color-grey-light) !important;
  color: var(--color-grey);
  cursor: default;
}
button:not(.NgxEditor__Color).success,
a.button:not(.NgxEditor__Color).success {
  color: white;
  background-color: var(--color-success);
}
button:not(.NgxEditor__Color).danger,
a.button:not(.NgxEditor__Color).danger {
  color: white;
  background-color: var(--color-danger);
}
button:not(.NgxEditor__Color).icon,
a.button:not(.NgxEditor__Color).icon {
  padding: 4px;
  border-radius: 6px;
  min-width: unset;
}
button:not(.NgxEditor__Color) img,
a.button:not(.NgxEditor__Color) img {
  height: 14px;
}

/*
  INPUTS
*/
/*
  input.ng-valid {}
  input.ng-invalid {}
  input.ng-pending {}
  input.ng-pristine {}
  input.ng-dirty {}
  input.ng-untouched {}
  input.ng-touched {}
*/
input,
textarea {
  padding: 10px 20px;
  border: solid 1px var(--color-grey-light);
  font-size: 14px;
  /* outline: solid 1px rgba(0, 0, 0, 0.02);*/
  outline: none;
  background-color: white;
}
input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border-color: var(--color-danger);
}

input[type=datetime-local],
input[type=date] {
  padding: 9px 20px;
}

input:focus,
textarea:focus {
  border-color: var(--color-light);
}

textarea {
  resize: vertical;
}

input.search {
  padding-right: 36px;
  background-image: url("/assets/icons/search.svg");
  background-size: 24px 24px;
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
  min-width: 100px;
  width: 100%;
}

input.search:focus {
  border-color: var(--color-blue-light);
}

input[readonly],
textarea[readonly],
input[disabled],
textarea[disabled] {
  border-color: var(--color-secondary);
  color: var(--color-text-cr);
}

input[type=checkbox] {
  zoom: 1.5;
  margin: 0;
}

select {
  padding: 10px 50px 10px 20px;
  border: solid 1px var(--color-grey-light);
  font-size: 14px;
  outline: solid 1px rgba(0, 0, 0, 0.02);
  color: var(--color-text-cr);
  background-color: white;
  background-image: url("/assets/icons/chevron-down.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.ng-invalid.ng-touched {
  border-color: var(--color-danger);
}

option {
  border: none;
}

/*
  IMAGE INPUT
*/
.thumbs {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.thumbs-item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-clip: content-box;
  border-radius: 8px;
  border: solid 2px transparent;
  padding: 0px;
  width: 60px;
  height: 60px;
  transition: 0.1s;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.02);
}

.thumbs-item.selected {
  border-color: var(--color-success);
  padding: 2px;
}

/*
  TABLE
*/
.table {
  display: flex;
  flex-direction: column;
}

.table .cell {
  flex: 0 1 18%;
  padding: 12px 10px 12px 30px;
  font-weight: 600;
}

.table-head {
  display: flex;
}

.table-head .cell {
  color: var(--color-grey);
}

.table-head .cell:last-of-type {
  flex: 1 1 0;
}

.table-body {
  border: solid 1px var(--color-grey);
}

.table-body-row {
  display: flex;
}

.table-body-row .cell {
  color: var(--color-blue-dark);
}

.table-body-row .cell:last-of-type {
  flex: 1 1 0;
}

/*
    MODAL
*/
.modal-body {
  border-radius: 16px;
  padding: 24px;
  background-color: white;
  width: 100%;
  max-width: 500px;
  min-height: 200px;
  text-align: center;
}

/*
    BREADCRUMBS
*/
.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 600;
  padding: 30px 0;
}

.breadcrumbs img {
  width: 24px;
}

.breadcrumbs .slash {
  color: var(--color-grey);
}

.breadcrumbs a {
  color: var(--color-blue);
}

.breadcrumbs span {
  color: var(--color-grey);
}

/*
* TABS
*/
.tabs-head {
  display: flex;
}

.tabs-head a {
  font-weight: 600;
  padding: 10px 16px;
  border: solid 1px var(--color-grey-light);
}

.tabs-head a.active {
  color: var(--color-blue-dark);
  background-color: var(--color-grey-light);
}

.tabs-head button {
  font-weight: 600;
  padding: 10px 16px;
  border: solid 1px var(--color-grey-light);
  background: none;
  color: var(--color-blue-light);
}

.tabs-head button.active {
  color: var(--color-blue-dark);
  background-color: var(--color-grey-light);
  color: var(--color-blue-dark);
  cursor: context-menu;
}

/* 
* LINDE
*/
/* TODO: REMOVE other items '.star' */
img.star {
  width: 16px;
  opacity: 0.4;
  filter: grayscale(1);
  cursor: pointer;
}

img.star.active {
  opacity: 1;
  filter: none;
}

.msg {
  font-size: 18px;
  font-weight: 500;
}

.success {
  color: var(--color-success);
}

.warning {
  color: var(--color-warning);
}

.danger {
  color: var(--color-danger);
}

* {
  --color-blue-light: #02a0e1;
  --color-blue: #015690;
  --color-blue-dark: #00305c;
  --color-blue-button1: #005590;
  --color-blue-button2: #25a6e2;
  --color-text-cr: #5f7789;
  --color-grey-light: #e5edef;
  --color-grey: #a5b3bd;
  --color-grey-dark: #404040;
  --color-danger: #e10256;
  --color-success: #2ebd78;
  --color-warning: #dab400;
  /*
  --color-primary: #F4770B;
  --color-secondary: #B9BAC5;
  --color-light: #EBEBEB;
  --color-text: #321A31;
  --color-success: #6BA56D;
  --color-warning: rgb(224, 224, 0);
  --color-danger: #F62915;
  --color-bg: #FCFCFC;
  */
}

/*
  --color-primary: rgb(240, 140, 0);
  --color-secondary: rgb(201, 201, 201);
  --color-text: rgb(109, 114, 122);
  --color-success: rgb(0, 150, 25);
  --color-warning: rgb(240, 240, 0);
  --color-danger: rgb(200, 52, 52);
  --color-bg: rgb(237, 244, 251);
*/
ngx-editor .NgxEditor {
  border-radius: 0;
  border: none;
  border-top: solid 1px var(--color-grey-light);
}
ngx-editor .NgxEditor__Content {
  padding: 10px 20px;
}

ngx-editor-menu .NgxEditor__MenuBar {
  padding: 10px 20px;
}
ngx-editor-menu .NgxEditor__MenuItem--IconContainer {
  border-radius: 0;
  border: solid 1px var(--color-grey-light);
}