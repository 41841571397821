* {
  --color-blue-light: #02a0e1;
  --color-blue: #015690;
  --color-blue-dark: #00305c;

  --color-blue-button1: #005590;
  --color-blue-button2: #25a6e2;

  --color-text-cr: #5f7789;

  --color-grey-light: #e5edef;
  --color-grey: #a5b3bd;
  --color-grey-dark: #404040;
  --color-danger: #e10256;
  --color-success: #2ebd78;
  --color-warning: #dab400;
  /*
  --color-primary: #F4770B;
  --color-secondary: #B9BAC5;
  --color-light: #EBEBEB;
  --color-text: #321A31;
  --color-success: #6BA56D;
  --color-warning: rgb(224, 224, 0);
  --color-danger: #F62915;
  --color-bg: #FCFCFC;
  */
}

/*
  --color-primary: rgb(240, 140, 0);
  --color-secondary: rgb(201, 201, 201);
  --color-text: rgb(109, 114, 122);
  --color-success: rgb(0, 150, 25);
  --color-warning: rgb(240, 240, 0);
  --color-danger: rgb(200, 52, 52);
  --color-bg: rgb(237, 244, 251);
*/
