.none {
  display: none;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  gap: 12px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
